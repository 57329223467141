<template>
  <div>
    <div class="flix-form-group">
      <transition name="flixFadeIn">
        <holidayEditor :key="$route.params.id" :id="$route.params.id" @save="setSave" />
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    holidayEditor () { return import('@/components/holidays/holidays.vue') }
  },
  props: {},
  data () {
    return {

    }
  },
  methods: {
    setSave () {
      this.$emit('save')
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @media (max-width: 500px)
    .flix-breadcrumb
      > .flix-html-li
        display: block
        padding: 2px 0
        font-size: inherit

        + .flix-html-li:before
          content: ""
          padding: 0
</style>
